// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artigos-e-pesquisas-js": () => import("./../../../src/pages/artigos-e-pesquisas.js" /* webpackChunkName: "component---src-pages-artigos-e-pesquisas-js" */),
  "component---src-pages-entendendo-moebius-js": () => import("./../../../src/pages/entendendo-moebius.js" /* webpackChunkName: "component---src-pages-entendendo-moebius-js" */),
  "component---src-pages-eventos-e-noticias-js": () => import("./../../../src/pages/eventos-e-noticias.js" /* webpackChunkName: "component---src-pages-eventos-e-noticias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instituicoes-e-clinicas-js": () => import("./../../../src/pages/instituicoes-e-clinicas.js" /* webpackChunkName: "component---src-pages-instituicoes-e-clinicas-js" */),
  "component---src-pages-nossas-historias-js": () => import("./../../../src/pages/nossas-historias.js" /* webpackChunkName: "component---src-pages-nossas-historias-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-tratamentos-disponiveis-js": () => import("./../../../src/pages/tratamentos-disponiveis.js" /* webpackChunkName: "component---src-pages-tratamentos-disponiveis-js" */)
}

